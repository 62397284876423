
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Rubik', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #26272b;
  color: #f4f4f4;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

a{
    color: #f4f4f4;
    text-decoration: none;
}

h1 {
    text-align: center;
    margin: 1rem;
}

.navbar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
   font-size: 2rem;
   color: #6900ff;
}

.purple {
    color: #6900ff;

}

.container {
    max-width: 1140px;
    margin: auto;
}

.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #26272b;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
    font-weight: 700;
}

.coin-name{
    margin-left: -4rem;
}

.coin-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #26272b;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
}

.coin-row:hover{
    transform: scale(1.04);
    transition: .3s ease-in-out;
    cursor: pointer;
}

.img-symbol {
    display: flex;
    align-items: center;
}

img {
    height: 40px;
    margin-right: 8px;
    width: auto;
}



@media screen and (max-width:720px) {

    .hide-mobile {
        display: none;
    }

    
}



/* 
.coin-container .content{
    max-width: 740px;
    margin: 1rem auto;
    padding: .7rem 1rem;
    display: flex;
    flex-direction: column;
    background-color: #26272b;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
}

.rank {
    margin: .5 0;
}

.rank-btn {
    border: 1px solid #6900ff;
    box-shadow: 0px 0px 8px #6900ff;
    background-color: #6900ff;
    border-radius: 8px;
    padding: .2rem;
}

.info{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}


.info .coin-heading {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.info .coin-price {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info p {
    padding-right: 1rem;
}

table {
    margin: .5rem 0;
}
td ,th {
    padding: 8px;
    text-align: center;
}

th {
    background-color: #333;
}

.status {
     display: grid;
     grid-template-columns: repeat(2,1fr);
     grid-gap: 2rem;
     width: 100%;
}

.stats .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #808080;
    margin: .6rem 0 ;
    padding-bottom: .5rem;
}

.stats .row p:first-child {
    color: #d3d3d3;
   
}

@media screen and (max-width:700px) {

    .coin-container .content {
        max-width: 100%;
        margin: .5rem;
        padding: 0 .5rem;
    }

    .stats {
        grid-template-columns: 1fr;
    }

    td ,th {
        padding: 3px;
    }
    
}
 */
